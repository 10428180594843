<template>
  <div class="container-fluid">
    <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
      <div class="row">
        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_AUCTIONS)"
          :to="{ name: 'List Auctions' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="ni ni-world-2 ni-2x"></i>
              <!--              <i class="fab fa-accusoft ni-2x"></i>-->
            </span>
            <small>{{ $t("COMMON.AUCTIONS") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_PROPERTIES)"
          :to="{ name: 'List Properties' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="fab fa-accusoft ni-2x"></i>
            </span>
            <small>{{ $t("COMMON.PROPERTIES") }}</small>
          </a>
        </router-link>

        <router-link
          v-if="$currentUserCan($permissions.PERM_VIEW_MODULE_COMMUNICATIONS)"
          :to="{ name: 'List Communications' }"
          class="col-4 shortcut-item"
          v-slot="{ href, navigate }"
          custom
        >
          <a :href="href" @click="navigate">
            <span
              class="shortcut-media avatar avatar-xl rounded-circle bg-info"
            >
              <i class="fab fa-accusoft ni-2x"></i>
            </span>
            <small>{{ $t("COMMON.COMMUNICATIONS") }}</small>
          </a>
        </router-link>
      </div>
    </card>
  </div>
</template>

<script>
export default {
  layout: "DashboardLayout",

  components: {},

  mixins: [],

  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>
